var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("Sound Kits ")]),
          _c("h1", [
            _vm._v(_vm._s(_vm.isNewRoute ? "New" : "Edit") + " Sound Kit "),
          ]),
        ]),
        _c("div", { staticClass: "headerContainer__alignRight" }, [
          _c(
            "a",
            {
              staticClass: "btn btn--sm btn--black btn--text btn--withIconLeft",
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
            },
            [
              _c("icon", {
                attrs: { name: "arrow-left", width: "14", height: "14" },
              }),
              _vm._v("GO BACK"),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      !_vm.isNewRoute && !_vm.soundKit && _vm.isLoading
        ? _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [_c("VLoadSpinner")],
            1
          )
        : _c("div", { staticClass: "container container--fullWidth" }, [
            _c(
              "div",
              {
                staticClass:
                  "container container--fullWidth grid grid__col-one-third",
              },
              [
                _c("div", { staticClass: "flexbox flexbox--center" }, [
                  _c("div", [
                    _c("div", { staticClass: "formGroup" }, [
                      _c("h4", { staticClass: "form__title h-textCenter" }, [
                        _vm._v("Sound Kit Image"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "formUpload formUpload--square",
                          class: {
                            "formUpload--empty": !_vm.soundKit.image_url,
                            "formUpload--uploaded": _vm.soundKit.image_url,
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "formUpload__icon",
                            attrs: { name: "camera" },
                          }),
                          _vm.soundKit.image_url
                            ? _c("img", {
                                staticClass: "formUpload__image",
                                attrs: { src: _vm.soundKit.image_url },
                              })
                            : _vm._e(),
                          _c("input", {
                            staticClass: "formUpload__inputHide",
                            attrs: { type: "file" },
                            on: {
                              change: function ($event) {
                                return _vm.uploadFile(
                                  $event.target.files,
                                  _vm.FileToRemoveOrAdd.Image
                                )
                              },
                            },
                          }),
                          _vm.soundKit.image_url
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn--danger btn--sm formUpload__btnDelete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFile(
                                        _vm.FileToRemoveOrAdd.Image
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Remove")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "h-mt-40" }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "formGroup wrapper--filled wrapper--paddingSm",
                      },
                      [
                        _c("label", { staticClass: "formLabel h-textCenter" }, [
                          _vm._v("Sample Audio (MP3)"),
                        ]),
                        !_vm.soundKit.audio_demo_file_name
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "formUpload formUpload--sm formUpload--empty",
                              },
                              [
                                _c("icon", {
                                  staticClass: "formUpload__icon",
                                  attrs: { name: "note" },
                                }),
                                _c(
                                  "label",
                                  { staticClass: "formUpload__labelDropzone" },
                                  [_vm._v("Drop file here")]
                                ),
                                _c("input", {
                                  staticClass: "formUpload__inputHide",
                                  attrs: { type: "file" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadFile(
                                        $event.target.files,
                                        _vm.FileToRemoveOrAdd.AudioDemoFile
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.soundKit.audio_demo_file_name
                          ? _c("div", { staticClass: "h-mt-10 h-textCenter" }, [
                              _c(
                                "audio",
                                {
                                  attrs: {
                                    controls: "",
                                    src: _vm.soundKit.audio_demo_file_name,
                                  },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Your browser does not support the audio element."
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "flexbox--alignRight btn btn--xs btn--danger btn--border",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFile(
                                        _vm.FileToRemoveOrAdd.AudioDemoFile
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Remove Demo File ")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _c("ValidationObserver", [
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c(
                        "div",
                        { staticClass: "formGroup formGroup--horizontal" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex75" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Name",
                                  rules: { required: true },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "label",
                                          { staticClass: "formLabel" },
                                          [_vm._v("Name")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.soundKit.name,
                                              expression: "soundKit.name",
                                            },
                                          ],
                                          staticClass:
                                            "formInput formInput--fullWidth",
                                          class: {
                                            hasError: errors.length > 0,
                                          },
                                          attrs: {
                                            name: "name",
                                            type: "text",
                                            placeholder: "Enter service name",
                                          },
                                          domProps: {
                                            value: _vm.soundKit.name,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.soundKit,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "formInput__hint formInput__hint--error",
                                          },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex25" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Price",
                                  rules: { required: true, numeric: true },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "label",
                                          { staticClass: "formLabel" },
                                          [_vm._v("Price")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.number",
                                              value: _vm.soundKit.price,
                                              expression: "soundKit.price",
                                              modifiers: { number: true },
                                            },
                                          ],
                                          staticClass:
                                            "formInput formInput--fullWidth",
                                          class: {
                                            hasError: errors.length > 0,
                                          },
                                          attrs: {
                                            name: "price",
                                            type: "text",
                                            placeholder: "20",
                                          },
                                          domProps: {
                                            value: _vm.soundKit.price,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.soundKit,
                                                "price",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "formInput__hint formInput__hint--error",
                                          },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "formGroup" },
                        [
                          _c("label", { staticClass: "formLabel" }, [
                            _vm._v("Description"),
                          ]),
                          _c("quill-editor", {
                            staticClass: "formInput",
                            staticStyle: { height: "260px", padding: "0" },
                            attrs: {
                              content: _vm.soundKit.description,
                              options: _vm.editorOption,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onEditorChange($event)
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "formLabel",
                              staticStyle: { "white-space": "normal" },
                            },
                            [_vm._v("Note: Maximum 1000 characters.")]
                          ),
                        ],
                        1
                      ),
                      _c("hr", { staticClass: "form__divider" }),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("h4", { staticClass: "form__title h-mb-10" }, [
                          _vm._v("Upload Sound Files (ZIP or RAR)"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "formUpload formUpload--sm formUpload--empty",
                          },
                          [
                            _c("icon", {
                              staticClass: "formUpload__icon",
                              class: {
                                "formUpload__icon--primary":
                                  _vm.soundKit.kit_file_name,
                              },
                              attrs: { name: "zip" },
                            }),
                            _vm.soundKit && _vm.soundKit.kit_file_name
                              ? _c("h4", [
                                  _vm._v(_vm._s(_vm.drumKitFileNameCleaned)),
                                ])
                              : _vm._e(),
                            _c(
                              "label",
                              { staticClass: "formUpload__labelDropzone" },
                              [
                                _vm._v(
                                  _vm._s(
                                    !_vm.soundKit.kit_file_name
                                      ? "Drop file here"
                                      : "Re-upload file here"
                                  )
                                ),
                              ]
                            ),
                            _c("input", {
                              staticClass: "formUpload__inputHide",
                              attrs: { type: "file" },
                              on: {
                                change: function ($event) {
                                  return _vm.uploadFile(
                                    $event.target.files,
                                    _vm.FileToRemoveOrAdd.KitFile
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
    ]),
    _c("section", { staticClass: "section__bottom-button" }, [
      _c(
        "a",
        { staticClass: "btn btn--primary", on: { click: _vm.saveAllChanges } },
        [_vm._v("SAVE CHANGES")]
      ),
      _vm.existsOnDatabase
        ? _c(
            "a",
            {
              staticClass: "btn btn--danger btn--border",
              on: { click: _vm.openConfirmActionModal },
            },
            [_vm._v("DELETE KIT")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }